<template>
  <div id="settings-wrapper">
    <div v-if="$acl.check('isSeller')">
      <v-card
        id="settings"
        class="py-2 px-4"
        color="rgba(37, 90, 144, .7)"
        dark
        flat
        link
        min-width="100"
        style="position: fixed; top: 115px; right: -35px; border-radius: 8px;"
      >
        <v-icon large>
          mdi-cart
        </v-icon>
      </v-card>

      <v-menu
        :close-on-content-click="false"
        activator="#settings"
        bottom
        content-class="v-settings"
        left
        nudge-left="8"
        offset-x
        origin="top right"
        transition="scale-transition"
      >
        <v-card class="text-center mb-0" width="375">
          <v-card-text>
            <strong class="mb-3 d-inline-block">CHECKOUT</strong>

            <v-divider class="my-4 secondary" />

            <Shopside ref="shopside"> </Shopside>

            <div class="my-12" />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
// Mixins
import Proxyable from "vuetify/lib/mixins/proxyable";
import { mapState } from "vuex";
import Shopside from "../../../../components/Shop-side.vue";

export default {
  name: "DashboardCoreSettings",
  components: {
    Shopside,
  },
  mixins: [Proxyable],

  computed: {
    ...mapState(["barImage"]),
  },

  methods: {
    mounted() {
      setTimeout(() => {
        this.componentKey += 1;
      }, 200);
    },
  },
};
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
